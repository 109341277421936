import { css, Global } from '@emotion/react'
import { varKeys as colorVarKeys } from 'components/src/theme/colors'
import { varKeys as typographyVarKeys } from 'components/src/theme/typography'
import { varKeys as utilVarKeys } from 'components/src/theme/utils'

const ThemeOverrides = () => (
    <Global
        styles={css`
            :root {
                ${utilVarKeys.topbarMenuOffset}: 94px;

                ${colorVarKeys.grayDark}: #000;
                ${colorVarKeys.primary}: #798799;
                ${colorVarKeys.primaryExtra}: #37464b;

                ${colorVarKeys.secondary}: #d6d6d2;
                ${colorVarKeys.secondaryExtra}: #460c0c;

                ${colorVarKeys.badgeBg}: #798799;

                ${colorVarKeys.textGrey}: #505050;

                ${colorVarKeys.onBackground}: #37464b;
                ${colorVarKeys.darkBackground}: #37464b;

                ${typographyVarKeys.fonts.primary}: 'Gotham', Helvetica, Arial,
                    sans-serif;
                ${typographyVarKeys.fonts
                    .secondary}: 'Gotham', Helvetica, Arial,
                    sans-serif;
                ${colorVarKeys.promoBannerBackground}: var(
                    ${colorVarKeys.sale}
                );

                ${colorVarKeys.footerTopBackground}: #85929e;
                ${colorVarKeys.footerMainBackground}: #2d3e4b;
                ${colorVarKeys.footerTopBottomBorder1}: none;
            }
        `}
    />
)

export default ThemeOverrides
